import { render, staticRenderFns } from "./forgetPassword.vue?vue&type=template&id=149e487a&scoped=true"
import script from "./forgetPassword.vue?vue&type=script&lang=js"
export * from "./forgetPassword.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "149e487a",
  null
  
)

export default component.exports