<template>
  <div>
    <Header/>
    <Breadcrumbs title="Mot de Passe oublié"/>
    <section class="pwd-page section-b-space">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <h2>{{ title }}</h2>
            <small>
              Écrivez votre email puis cliquez sur envoyer et vous recevrez automatiquement sur votre boîte mail un lien
              vous permettant d’accéder à votre compte.
            </small>
            <ValidationObserver ref="forgotPasswordForm">
            <form class="theme-form">
              <div class="form-row">
                <div class="col-md-12">
                  <ValidationProvider rules="required|email" :customMessages="{
                      required: 'Nouveau Mot de passe utilisateur?',
                      email : 'Invalide Email'
                    }" v-slot="{ errors }" name="email">
                    <input
                        type="email"
                        class="form-control"
                        id="email"
                        v-model="form.email"
                        placeholder="Entrer votre Email"
                        name="email"
                        required
                    />
                    <span class="validate-error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <button type="button" @click="submitForgotpasswordForm" class="btn btn-solid">Envoyer</button>
              </div>
            </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from "../../../components/header/appHeader";
import Footer from "../../../components/footer/appFooter";
import Breadcrumbs from "../../../components/widgets/breadcrumbs";

import axios from "axios";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full.esm";

export default {
  name: "forgetPassword",

  components: {
    Header,
    Footer,
    Breadcrumbs,
    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      title: "Mot de Passe oublié?",
      form: {
        email: null
      }

    };
  },
  methods: {
    async submitForgotpasswordForm() {
      let loader = this.$loading.show();
      const valid = await this.$refs.forgotPasswordForm.validate();
      if (valid) {
        axios.post("forget-password", this.form)
            .then(response => {
              loader.hide();
              if (response.data.success) {
                this.$swal("",
                    response.data.message,
                    "success");
              } else {
                this.$swal("Oops",
                    response.data.message,
                    "error");
              }
            })
            .catch(error => {
              loader.hide();
              this.$swal("Oops",
                  error.message,
                  "error");
            });
      } else {
        loader.hide();
      }
    }
  }
};
</script>

<style scoped>

</style>
