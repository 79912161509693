<template>
  <div>
    <Header/>
    <Breadcrumbs title="Mot de Passe oublié"/>
    <section class="pwd-page section-b-space">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <h2>{{ title }}</h2>
            <ValidationObserver ref="resetForm">
              <form class="theme-form">
                <div v-if="errors.length">
                  <ul class="validation-error mb-3">
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                  </ul>
                </div>
                <div class="form-row">
                  <div class="col-md-12">
                    <ValidationProvider rules="required|email" :customMessages="{
                      required: 'Le champs Email est obligatoire',
                      email: 'Votre Email n\'est pas valide'
                    }" v-slot="{ errors }" name="Email">
                      <input
                          type="email"
                          class="form-control"
                          id="email"
                          v-model="form.email"
                          placeholder="Entrer votre Email"
                          name="email"
                          required
                      />
                      <span class="validate-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12">
                    <ValidationProvider rules="required" vid="password" :customMessages="{
                      required: 'Nouveau Mot de passe utilisateur?'
                    }" v-slot="{ errors }" name="password">
                      <input
                          type="password"
                          class="form-control"
                          id="password"
                          v-model="form.password"
                          placeholder="Entrer votre Nouveau Mot de Passe"
                          name="email"
                          required
                      />
                      <span class="validate-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <ValidationProvider rules="required|confirmed:password" vid="confirmPassword" :customMessages="{
                      required: 'Confirmer Mot de passe',

                    }" v-slot="{ errors }" name="confirm_password">
                      <input
                          type="password"
                          class="form-control"
                          id="password_confirmation"
                          v-model="form.password_confirmation"
                          placeholder="Confirmer Mot de Passe"
                          name="confirm_password"
                          required
                      />
                      <span class="validate-error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <button @click="submitResetpasswordForm" class="btn btn-solid">Envoyer</button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from "../../../components/header/appHeader";
import Footer from "../../../components/footer/appFooter";
import Breadcrumbs from "../../../components/widgets/breadcrumbs";

import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

import axios from "axios";

export default {
  name: "forgetPassword",

  components: {
    Header,
    Footer,
    Breadcrumbs,
    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      title: "Réinitialiser mot de passe",
      errors: [],
      form: {
        email: null,
        password: '',
        password_confirmation: ''
      }

    };
  },
  methods: {

    async submitResetpasswordForm() {
      let loader = this.$loading.show();
      const valid = await this.$refs.resetForm.validate();
      if (valid) {
        axios.post("reset-password", Object.assign(this.form, {token: this.$route.params.token}))
            .then(response => {
              if (response.data.success) {
                loader.hide();
                this.$swal("",
                    response.data.message,
                    "success");
                this.$router.push({name: "Login"});
              }
            })
            .catch(error => {
              loader.hide();
              this.$swal("Oops",
                  error.message,
                  "error");
            });
      } else {
        loader.hide();
      }
    }
  }
};
</script>

<style scoped>

</style>
