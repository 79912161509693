var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('Breadcrumbs',{attrs:{"title":"Mot de Passe oublié"}}),_c('section',{staticClass:"pwd-page section-b-space"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 offset-lg-3"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('ValidationObserver',{ref:"resetForm"},[_c('form',{staticClass:"theme-form"},[(_vm.errors.length)?_c('div',[_c('ul',{staticClass:"validation-error mb-3"},_vm._l((_vm.errors),function(error,index){return _c('li',{key:index},[_vm._v(_vm._s(error))])}),0)]):_vm._e(),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"rules":"required|email","customMessages":{
                    required: 'Le champs Email est obligatoire',
                    email: 'Votre Email n\'est pas valide'
                  },"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"type":"email","id":"email","placeholder":"Entrer votre Email","name":"email","required":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"password","customMessages":{
                    required: 'Nouveau Mot de passe utilisateur?'
                  },"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",attrs:{"type":"password","id":"password","placeholder":"Entrer votre Nouveau Mot de Passe","name":"email","required":""},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","vid":"confirmPassword","customMessages":{
                    required: 'Confirmer Mot de passe',

                  },"name":"confirm_password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password_confirmation),expression:"form.password_confirmation"}],staticClass:"form-control",attrs:{"type":"password","id":"password_confirmation","placeholder":"Confirmer Mot de Passe","name":"confirm_password","required":""},domProps:{"value":(_vm.form.password_confirmation)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password_confirmation", $event.target.value)}}}),_c('span',{staticClass:"validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('button',{staticClass:"btn btn-solid",on:{"click":_vm.submitResetpasswordForm}},[_vm._v("Envoyer")])])])])],1)])])]),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }